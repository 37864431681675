/**
 * Returns deep cloned object
 * @param {Object} obj
 * @returns {Object}
 */
export const cloneDeep = (obj) => {
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }

    let clone;

    if (Array.isArray(obj)) {
        clone = [];
        for (let i = 0; i < obj.length; i++) {
            clone[i] = cloneDeep(obj[i]);
        }
    } else {
        clone = {};
        for (let key in obj) {
            // eslint-disable-next-line no-prototype-builtins
            if (obj.hasOwnProperty(key)) {
                clone[key] = cloneDeep(obj[key]);
            }
        }
    }
    return clone;
};


const arrayConditionHelper = (value1, value2) => {
    return Array.isArray(value1) && value1.length !== value2.length;
};

const recursiveObjectEqualityCheck = (value1, value2) => {
    const p = Object.keys(value1);
    return Object.keys(value2).every(index => {
        return p.indexOf(index) !== -1;
    }) &&
        p.every(index => {
            return isEqual(value1[index], value2[index]);
        });
};

const isFuncOrRegEx = (value1) => {
    return value1 instanceof Function || value1 instanceof RegExp;
};

const constructorsNotEqual = (value1, value2) => {
    return value1.constructor !== value2.constructor;
};

const isValEqual = (value1, value2) => {
    return value1 === value2 || value1.valueOf() === value2.valueOf();
};

const isDateOrNotObject = (value1, value2) => {
    return value1 instanceof Date || !(value1 instanceof Object) || !(value2 instanceof Object);
};

/**
 * Checks whether given two values are same or not
 *
 * @param   {*} value1
 * @param   {*} value2
 * @returns {Boolean}
*/
export const isEqual = (value1, value2) => {
    if (isNil(value1) || isNil(value2)) {
        return value1 === value2;
    }
    // after this just checking type of one would be enough
    if (constructorsNotEqual(value1, value2) || arrayConditionHelper(value1, value2)) {
        return false;
    }

    // if they are functions, they should exactly refer to same one (because of closures)
    // if they are regexps, they should exactly refer to same one (it is hard to better equality check on current ES)
    if (isFuncOrRegEx(value1)) {
        return value1 === value2;
    }

    if (isValEqual(value1, value2)) {
        return true;
    }

    // if they are dates, they must had equal valueOf
    // if they are strictly equal, they both need to be object at least
    if (isDateOrNotObject(value1, value2)) {
        return false;
    }

    // recursive object equality check
    return recursiveObjectEqualityCheck(value1, value2);
};


/**
 * Checks whether the given value is empty
 * @param   {*}       value
 * @returns {Boolean}
 *
 */
export const isEmpty = (value) => {
    if (value === null || value === undefined) {
        return true;
    }

    if (typeof value === 'string' || Array.isArray(value)) {
        return value.length === 0;
    }

    if (typeof value === 'object') {
        return Object.keys(value).length === 0;
    }

    if (typeof value === 'boolean') {
        return false;
    }

    if (typeof value === 'number') {
        return value < 0;
    }
    return true;
};

/**
 * Checks whether passed param is an Array
 * @param   {*} value
 * @returns {Boolean}
*/
export const isArray = value => {
    return !!value && Array.isArray(value);
};

/**
 * Runs Array.map function on the value, func provided
 * @param   {Array} value
 * @param   {Function} callback
 * @returns {Array}
*/
export const map = (collection, callback) => {
    if (Array.isArray(collection)) {
        const result = [];
        for (let i = 0; i < collection.length; i++) {
            result.push(callback(collection[i], i, collection));
        }
        return result;
    } else if (typeof collection === 'object') {
        const result = [];
        for (const key in collection) {
            // eslint-disable-next-line no-prototype-builtins
            if (collection.hasOwnProperty(key)) {
                result.push(callback(collection[key], key, collection));
            }
        }
        return result;
    }
    return [];
};

/**
 * Runs Array.filter function on the value, func provided
 * @param   {Array} value
 * @param   {Function} callback
 * @returns {Array}
*/

export const filter = (collection, callback) => {
    if (Array.isArray(collection)) {
        const result = [];
        for (let i = 0; i < collection.length; i++) {
            if (callback(collection[i], i, collection)) {
                result.push(collection[i]);
            }
        }
        return result;
    } else if (typeof collection === 'object') {
        const result = [];
        for (const key in collection) {
            // eslint-disable-next-line no-prototype-builtins
            if (collection.hasOwnProperty(key) && callback(collection[key], key, collection)) {
                result.push(collection[key]);
            }
        }
        return result;
    }
    return [];
};


/**
 * Runs Array.find function on the value, func provided
 * @param   {Array} value
 * @param   {Function} callback
 * @returns {Array}
*/

export const find = (collection, predicate) => {
    if (Array.isArray(collection)) {
        for (let i = 0; i < collection.length; i++) {
            if (predicate(collection[i], i, collection)) {
                return collection[i];
            }
        }
    } else if (typeof collection === 'object') {
        for (const key in collection) {
            if (predicate(collection[key], key, collection)) {
                return collection[key];
            }
        }
    }
    return undefined;
};


/**
 * Checks if array includes compare value
 * @param   {Array} value
 * @param   {Function} func
 * @returns {Array}
*/
export const includes = (value, compare) => {
    return value.includes(compare);
};

/**
 * Returns intersection of all array passed
 * @param  {...Array} arrs
 * @returns {Array}
 */
export const intersection = (...arrs) => {
    let valArray = [];
    const longestArr = [...arrs].sort((a, b) => b.length - a.length)[0];
    for (const val of longestArr) {
        const inAllArrs = arrs.every((arr) => arr.includes(val));
        if (inAllArrs) {
            valArray.push(val);
        }
    }
    return [...new Set(valArray)];
};

/**
 * finds difference between two arrays mutates the first array
 * @param {Array} arr1
 * @param {Array} arr2
 */
export const pullAll = (arr1, arr2) => {
    for (const val of arr2) {
        const index = arr1.indexOf(val);
        if (index > -1) {
            arr1.splice(index, 1);
        }
    }
};

/**
 * @param {Collection <Array, Object>} collection
 * @param {Function} callback
 * @returns
*/
export const forEach = (collection, iteratee) => {
    if (isArray(collection)) {
        forEachArrayFn(collection, iteratee);
    } else if (isObject(collection)) {
        for (const key in collection) {
            // eslint-disable-next-line no-prototype-builtins
            if (collection.hasOwnProperty(key)) {
                if (iteratee(collection[key], key, collection) === false) {
                    break;
                }
            }
        }
    }
};

/**
 * @param {Collection <Array, Object>} collection
 * @param {Function} callback
 * @returns
*/
const forEachArrayFn = (collection, iteratee) => {
    for (let i = 0; i < collection.length; i++) {
        if (iteratee(collection[i], i, collection) === false) {
            break;
        }
    }
};


/**
 * Returns first element from the array
 * @param {Array} arr
 * @returns {any}
 */
export const first = (arr) => {
    return arr[0] || undefined;
};

/**
 * Checks whether first param starts with second parameter
 * @param {String} value
 * @param {String} compare
 * @returns {Boolean}
*/
export const startsWith = (value, compare) => {
    return value.startsWith(compare);
};

/**
 * returns all keys in the object as an Array
 * @param {String} value
 * @param {String} compare
 * @returns {Boolean}
*/
export const keys = (value) => {
    return Object.keys(value);
};

/**
 * returns all values in the object as an Array
 * @param {String} value
 * @param {String} compare
 * @returns {Boolean}
*/
export const values = (value) => {
    return Object.values(value);
};

/**
 * Checks whether first param ends with second parameter
 * @param {String} value
 * @param {String} compare
 * @returns {Boolean}
*/
export const endsWith = (value, compare) => {
    return value.endsWith(compare);
};

/**
 * Check is it an Object
 * @param   {*}       value
 * @returns {boolean}
 */
export const isObject = (value, strict = true) => {
    const type = typeof value;
    if (!value) {
        return false;
    }
    return (
        !isArray(value) && (type === 'object' || (!strict && type === 'function'))
    );
};

/**
 *
 * @param {function} fn - function to be called after the delay
 * @param {Number} ms   - delay in miliseconds
 * @returns
 */
export const debounce = (fn, ms = 0) => {
    let timeoutId = null;
    return (...args) => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => fn.apply(this, args), ms);
    };
};

/**
 *
 * @param   {Number}   count - counter to repeat the function call
 * @param   {function} fn    - function to be called
 * @returns {Array}          - result from of function stored in array
 */
export const times = (count = 0, fn = () => null) => {
    const result = [];
    while (count > 0) {
        count--;
        result.push(fn(count));
    }
    return result;
};

/**
 * returns sum of all numbers in the array
 * @param {Array} arr
 * @returns {Number}
 */
export const sum = (arr) => {
    return arr.reduce((total, num) => total + num, 0);
};

/**
 *
 * @param {Object} object
 * @param {Function} predicate
 * @returns { Object }
 */
export const pickBy = (object, predicate) => {
    const result = {};
    for (const key in object) {
        // eslint-disable-next-line no-prototype-builtins
        if (object.hasOwnProperty(key) && predicate(object[key], key)) {
            result[key] = object[key];
        }
    }
    return result;
};

/**
 * returns array of strings build using separator
 * @param {String} string
 * @param {String} separator
 * @returns {Array}
 */
export const split = (string, separator) => {
    if (typeof string !== 'string') {
        return [];
    }
    if (separator === undefined) {
        return [string];
    }
    return string.split(separator);
};

/**
 *
 * @param {Array} array
 * @param {Function} iteratee
 * @returns {Array}
 */
export const uniqBy = (array, iteratee) => {
    const seen = new Set();
    const result = [];
    for (const item of array) {
        const value = typeof iteratee === 'function' ? iteratee(item) : item[iteratee];
        if (!seen.has(value)) {
            seen.add(value);
            result.push(item);
        }
    }
    return result;
};

/**
 * Returns true if the value param is null or undefined
 * @param {*} value
 * @returns { Boolean }
*/
export const isNil = (value) => {
    return value === null || value === undefined;
};

/**
 *
 * @param {Array} array
 * @param {String} separator
 * @returns {String}
 */
export const join = (array, separator) => {
    if (!Array.isArray(array)) {
        return '';
    }

    if (separator === undefined) {
        separator = ',';
    }

    return array.reduce((result, item, index) => {
        if (index === 0) {
            return String(item);
        }
        return result + separator + String(item);
    }, '');
};

/**
 *
 * @param {Array} collection
 * @param {Function} iteratees
 * @param {*} orders
 * @returns {Array}
 */
export const orderBy = (collection, iteratees, orders) => {
    const compareValues = (a, b, iteratee, order) => {
        const aValue = typeof iteratee === 'function' ? iteratee(a) : a[iteratee];
        const bValue = typeof iteratee === 'function' ? iteratee(b) : b[iteratee];

        if (aValue < bValue) {
            return order === 'desc' ? 1 : -1;
        }
        if (aValue > bValue) {
            return order === 'desc' ? -1 : 1;
        }
        return 0;
    };

    return collection.sort((a, b) => {
        for (let i = 0; i < iteratees.length; i++) {
            const iteratee = iteratees[i];
            const order = orders[i] || 'asc';

            const result = compareValues(a, b, iteratee, order);
            if (result !== 0) {
                return result;
            }
        }
        return 0;
    });
};

/**
 * returns max value from an array
 * @param {Array} array
 * @returns {Number}
 */
export const max = (array) => {
    if (!Array.isArray(array) || array.length === 0) {
        return undefined;
    }
    let maxValue = array[0];
    for (let i = 1; i < array.length; i++) {
        if (array[i] > maxValue) {
            maxValue = array[i];
        }
    }
    return maxValue;
};

/**
 * Returns if Value passed is not a number
 * @param {Number} value
 * @returns {Boolean}
 */
export const isNaN = (value) => {
    return Number.isNaN(value);
};

/**
 * Converts String to camel case
 * @param {string} str
 * @returns {string}
 */

export const camelCase = (str) => {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, (match, index) => {
        return index === 0 ? match.toLowerCase() : match.toUpperCase();
    }).replace(/\s+/g, '');
};

/**
 * Flattens given array
 * const nestedArray = [1, [2, [3, [4]], 5]];
 * const flattenedArray = flatten(nestedArray);
 * // Output: [1, 2, 3, 4, 5]
 * @param {Array} array
 * @returns { Array }
*/
export const flatten = (array) => {
    const result = [];
    for (let i = 0; i < array.length; i++) {
        if (Array.isArray(array[i])) {
            result.push(...flatten(array[i]));
        } else {
            result.push(array[i]);
        }
    }
    return result;
};

/**
 * returns last element of an array passed
 * @param {Array} array
 * @returns {*}
 */
export const last = (array) => {
    if (!Array.isArray(array) || array.length === 0) {
        return undefined;
    }
    return array[array.length - 1];
};

/**
 * returns first element of an array passed
 * @param {Array} array
 * @returns {*}
*/
export const head = (array) => {
    if (!Array.isArray(array) || array.length === 0) {
        return undefined;
    }
    return array[0];
};

/**
 * Replaces string occurences with replacements matching the pattern
 * @param {String} string
 * @param {RegExp} pattern
 * @param {String} replacement
 * @returns {String}
*/
export const replace = (string, pattern, replacement) => {
    return string.replace(pattern, replacement);
};

/**
 * @param {Object} Object
 * @param {string} path
 * @param {any} defaultValue
 * @returns {any}
*/
export const get = (object, path, defaultValue) => {
    let pathArray = [];
    if (Array.isArray(path)) {
        pathArray = path;
    } else {
        pathArray = path.split('.');
    }
    let result = object;
    if (result === null || result === undefined)
        return defaultValue;
    for (const key of pathArray) {
        result = result[key];
        if (result === null || result === undefined) {
            return defaultValue;
        }
    }
    return result;
};

/**
 * @param {Boolean} condition
 * @param {any} trueVal
 * @param {any} falseVal
 * @returns {any}
*/
export const getConditonValue = (condition, trueVal, falseVal) => condition ? trueVal : falseVal;
